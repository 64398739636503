<template>
  <div class="contentmain">
    <div class="pagecontent" ref="exportPdf">
      <div class="person" v-if="resumeInfo.user">
        <div class="xaizai">
          <el-button type="warning" @click="download">下载简历</el-button>
        </div>
        <div class="p-pic">
          <img v-if="b64" :src="b64" />
          <img v-else src="../../assets/images/mycenter/yishiming.png" />
        </div>
        <div class="p-info">
          <div class="pi-one">
            <div class="font22">
              <span>{{ resumeInfo.user.displayName }}</span>
              <img
                src="../../assets/images/common/boy.png"
                v-if="resumeInfo.user.sex == 1"
              />
              <img src="../../assets/images/common/girl.png" v-else />
            </div>
          </div>
          <div class="pi-three font16">
            <div class="m-info">
              {{ resumeInfo.user.sex == 1 ? "男" : "女" }} |
              {{
                new Date().getYear() -
                new Date(resumeInfo.user.birthday).getYear()
              }}岁（{{ resumeInfo.user.birthday }})
            </div>
            <img
              class="dianhua"
              src="../../assets/images/mycenter/dianhua.png"
            />
            {{ resumeInfo.user.phone }}
            <img
              class="youxiang"
              src="../../assets/images/mycenter/youxiang.png"
            />
            {{ resumeInfo.user.email }}
          </div>
          <div
            class="pi-two font16"
            v-for="(item, index) in resumeInfo.userJobList"
            :key="index"
          >
            求职意向：{{ item.jobName }} | 期望薪资：
            {{ (item.salaryLow / 1000) | numFilter }}K - {{ (item.salaryHigh / 1000) | numFilter }}K
          </div>
        </div>
      </div>
      <div
        class="gonggong"
        v-if="resumeInfo.workExpList && resumeInfo.workExpList.length"
      >
        <div class="tit-header font18"><div class="p-tit">工作经历</div></div>
        <div
          class=""
          style="border-bottom: 1px solid #f3f3f3"
          v-for="(item, index) in resumeInfo.workExpList"
          :key="index"
        >
          <div class="position">
            <div class="p-left font18">
              {{ item.companyName }}
              <span class="font14">{{ item.jobName }}</span>
              <div class="time font14">
                {{ item.startDate }}-{{ item.endDate ? item.endDate : "/" }}
              </div>
            </div>
          </div>
          <div class="miaoshu">
            <div class="">{{ item.jobDescription }}</div>
          </div>
        </div>
      </div>
      <div
        class="gonggong"
        v-if="resumeInfo.educationList && resumeInfo.educationList.length"
      >
        <div class="tit-header font18">
          <div class="p-tit">教育经历</div>
        </div>
        <div
          class=""
          style="border-bottom: 1px solid #f3f3f3"
          v-for="(item, index) in resumeInfo.educationList"
          :key="index"
        >
          <div class="position">
            <div class="p-left font18">
              {{ item.schoolName }} | {{ item.major }} |
              {{ item.educationType?item.educationType.itemText:'' }}
              <div class="time font14">
                {{ item.startDate }}-{{ item.endDate ? item.endDate : "/" }}
              </div>
            </div>
          </div>
          <div class="miaoshu">
            <div class="">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <div
        class="gonggong"
        v-if="resumeInfo.projectExpList && resumeInfo.projectExpList.length"
      >
        <div class="tit-header font18">
          <div class="p-tit">项目经历</div>
        </div>
        <div
          class=""
          style="border-bottom: 1px solid #f3f3f3"
          v-for="(item, index) in resumeInfo.projectExpList"
          :key="index"
        >
          <div class="position">
            <div class="p-left font18">
              {{ item.projectName }}
              <div class="time font14">
                {{ item.projectStartDate }}-{{
                  item.projectEndDate ? item.projectEndDate : "/"
                }}
              </div>
            </div>
          </div>
          <div class="q-other" style="margin-top: 20px">
            <div class="qo-bqian font14">
              <span v-for="(sub, i) in item.projectTechnology" :key="i">{{
                sub.itemText
              }}</span>
            </div>
          </div>
          <div class="miaoshu">
            <div class="">{{ item.projectDescription }}</div>
          </div>
        </div>
      </div>
      <div
        class="gonggong"
        v-if="resumeInfo.honorCertList && resumeInfo.honorCertList.length"
      >
        <div class="tit-header font18">
          <div class="p-tit">资格证书</div>
        </div>
        <div
          class="book font16"
          v-for="(item, index) in resumeInfo.honorCertList"
          :key="index"
        >
          <div class="">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div
        class="gonggong"
        v-if="resumeInfo.languageList && resumeInfo.languageList.length"
      >
        <div class="tit-header font18">
          <div class="p-tit">外语能力</div>
        </div>
        <div
          class="book font16"
          v-for="(item, index) in resumeInfo.languageList"
          :key="index"
        >
          <div class="">
            {{ item.languageType.itemText }} | {{ item.languageLevel.itemText }}
          </div>
        </div>
      </div>
      <div
        class="gonggong"
        v-if="resumeInfo.userCv && resumeInfo.userCv.resUrl"
      >
        <div class="tit-header font18">
          <div class="p-tit">视频简历</div>
        </div>
        <div class="book font16">
          <div class="">
            {{ resumeInfo.userCv.cvName }}
          </div>
          <div class=""></div>
        </div>
        <div class="video" v-if="resumeInfo.userCv.resUrl">
          <div class="imgshowmain">
            <video
              v-bind:src="
                'https://freemen.work/user/file/view/' +
                resumeInfo.userCv.resUrl
              "
              class="avatar video-avatar"
              controls="controls"
            >
              您的浏览器不支持视频播放
            </video>
            <div class="videoBox">
              <img
                class="bofang"
                src="../../assets/images/common/bofang-img.png"
                @click="videoshow(resumeInfo.userCv)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      resumeInfo: {},
      b64: null,
    };
  },

  created() {
    this.getResumeDetail();
  },
  mounted() {},
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    download() {
      this.$PDFSave(
        this.$refs["exportPdf"],
        this.resumeInfo.user.displayName + "的简历"
      );
    },
    getResumeDetail() {
      //获取简历信息
      this.$api.viewResume("get").then((res) => {
        this.resumeInfo = res.data;

        this.getBase64(
          "https://freemen.work/user/file/view/" + res.data.user.avatar
        );
      });
    },
    getBase64(url) {
      var that = this;

      let Img = new Image(),
        dataURL = "";

      let imgExt = "jpg";

      Img.src = url + "?v=" + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;

      Img.setAttribute("crossOrigin", "Anonymous"); // 解决控制台跨域报错的问题（没用就跟后台沟通添加跨域）

      Img.onload = function () {
        //要先确保图片完整获取到，这是个异步事件

        let canvas = document.createElement("canvas"), //创建canvas元素
          width = Img.width, //确保canvas的尺寸和图片一样
          height = Img.height;

        canvas.width = width;

        canvas.height = height;

        canvas.getContext("2d").drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中

        dataURL = canvas.toDataURL("image/" + imgExt); //转换图片为dataURL

        that.b64 = dataURL;
      };
    },
    videoshow(data) {
      //播放视频
      Bus.$emit("videodstuts", {
        show: true,
        data: data,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pagecontent {
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 124px;
}
.p-tit {
  // width: 100%;
  height: 42px;
  background-color: #00ffff;
  color: #111111;
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 14px;
  background: url(../../assets/images/mycenter/jianlitit-bg.png) no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 2px;
}
.person {
  .xaizai {
    position: absolute;
    right: 30px;
    top: 10px;
    /deep/ .el-button {
      border-radius: 0px;
      background-color: #ff8400;
      width: 90px;
      height: 34px;
      color: #ffffff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  position: relative;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  padding: 20px 30px;
  .p-pic {
    width: 64px;
    margin-right: 30px;
    img {
      width: 64px;
      height: 64px;
      border-radius: 50px;
    }
  }
  .p-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    line-height: 1.8;
    .pi-one {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:first-child {
        display: flex;
        align-items: center;
        span {
          color: #111111;
        }
        img {
          width: 24px;
          height: 24px;
          margin-left: 10px;
        }
      }
      div:last-child {
        font-size: 20px;
        .edit {
          width: 22px;
          height: 18px;
        }
      }
    }
    .pi-two {
      color: #111111;
    }
    .pi-three {
      color: #111111;
      display: flex;
      align-items: center;
      .m-info {
        margin-right: 30px;
      }
      .zhiwei {
        width: 10px;
        height: 18px;
        margin-right: 10px;
      }
      .dianhua {
        width: 12px;
        height: 16px;
        margin-right: 10px;
        margin-left: 15px;
      }
      .youxiang {
        width: 15px;
        height: 12px;
        margin-right: 10px;
        margin-left: 15px;
      }
      .weixin {
        width: 20px;
        height: 16px;
        margin-right: 10px;
        margin-left: 15px;
      }
    }
  }
}

.gonggong {
  padding: 20px 30px;
  border-bottom: 1px solid #f3f3f3;
  .video {
    margin-top: 20px;
    .imgshowmain:hover {
      .el-icon-error {
        display: flex !important;
      }
    }
    .imgshowmain {
      margin-right: 30px;
      margin-top: 30px;
      position: relative;
      .video-avatar {
        width: 300px;
        height: 180px;
      }
      .video-avatar::-webkit-media-controls {
        display: none !important;
      }
      .videoBox {
        width: 300px;
        height: 180px;
        position: absolute;
        top: 0;
        left: 0;
      }
      img {
        width: 300px;
        height: 180px;
      }
      .bofang {
        position: absolute;
        width: 67px;
        height: 63px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .el-icon-error {
        width: 30px;
        height: 30px;
        top: 5px;
        right: 5px;
        position: absolute;
        font-size: 30px;
        color: #dddddd;
        display: none;
      }
    }
    .video-avatar {
      width: 240px;
      height: 160px;
    }
    .video-avatar::-webkit-media-controls {
      display: none !important;
    }
    img {
      width: 240px;
      height: 160px;
    }
  }
  .book {
    display: flex;
    align-items: center;
    margin-top: 20px;
    div:first-child {
      width: 300px;
      color: #111111;
    }
    // div:last-child {
    // 	color: #00bcff;
    // }
  }
  .tit-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .caozuo {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      color: #00bcff;
    }
  }
  .position {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .p-left {
      display: flex;
      align-items: center;
      flex: 1;
      color: #111111;
      span {
        color: #666666;
        margin-left: 15px;
      }
      .time {
        margin-left: 80px;
        color: #111111;
      }
    }
    .p-center {
      display: flex;
      align-items: center;
      flex: 1;
      color: #111111;
    }
    .p-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      .laji {
        width: 19px;
        height: 20px;
      }
      .edit {
        width: 22px;
        height: 18px;
        margin-right: 30px;
      }
    }
  }
  .q-other {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .qo-bqian {
      margin-right: 70px;
      span {
        background-color: #eeeeee;
        color: #444444;
        padding: 2px 8px;
        margin-right: 10px;
      }
    }
    .qo-time {
      display: flex;
      align-items: center;
      color: #111111;
      img {
        width: 40px;
        height: 40px;
        margin-right: 4px;
      }
      .dao {
        margin: 0 20px;
      }
    }
  }
  .miaoshu {
    margin-top: 24px;
    padding-bottom: 20px;
    color: #333333;
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
